// import axios from "axios"
import Api from '../../config/config.js'

export default {
    async post(options){
        try {
            let postPaiement = await Api.instance.post(`/paiement-modalite/generate`,options)
            console.log("postTarif",postPaiement)
            return postPaiement.data.create
        } catch (error) {
            console.log(error)
        }
    },
    async getBySaisonType(options){
        try {
            let getPaiement = await Api.instance.get(`/paiement-modalite/get-by-saison-type`,{params:options})
            console.log("getPaiement",getPaiement)
            return getPaiement.data
        } catch (error) {
            console.log(error)
        }
    },
    async put(options){
        try {
            let updatePaiement = await Api.instance.put(`/paiement-modalite/update`,options)
            console.log("updatePaiement",updatePaiement)
            return updatePaiement.data.update
        } catch (error) {
            console.log(error)
        }
    },
    async delete(options){
        try {
            let deletePaiement = await Api.instance.post(`/paiement-modalite/delete`,options)
            console.log("deletePaiement",deletePaiement)
            return deletePaiement.data.delete
        } catch (error) {
            console.log(error)
        }
    }    
}