<template>
  <div class="px-5 py-5 elevation-6 rounded">
    <div class="d-flex justify-space-between">
      <div class="d-flex action">
        <v-sheet
        color="primary "
        elevation="6"
        class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
        dark
        :max-width="65"
        @click="vue='voir',init()"
        style="cursor: pointer"
        >
          <v-icon size="32">mdi-clipboard-text</v-icon>
          <span class="text-caption text-uppercase">Voir</span>
        </v-sheet> 
        
        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="vue='creer',getSaison(),init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-pencil</v-icon>
          <span class="text-caption text-uppercase">Créer</span>
        </v-sheet>
      </div>
      <div class="d-flex save">
        <v-sheet
          :color="trash.save?'red':'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="supprimer()"
          style="cursor: pointer"
        >
          <v-icon  size="32">mdi-delete</v-icon>
          <span class="text-caption text-uppercase">Supprimer</span>
        </v-sheet>

        <v-sheet
          :color="create.save || update.save?'indigo':'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="save()"
          style="cursor: pointer"
        >
          <v-icon  size="32">mdi-content-save</v-icon>
          <span class="text-caption text-uppercase">Garder</span>
        </v-sheet>
      </div>   
    </div>
     <div v-if="vue=='voir'">
        <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Saison</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select @change="controlVoir()" v-model="voir.saison" :placeholder="'Saison'" :items="saison"></v-select>
                </v-responsive>
              </td>
            </tr>
          </tbody> 
        </v-simple-table>
        <v-simple-table v-if="arraySalle.length>0">
          <thead>
            <tr>
              <th class="primary--text">ID</th>
              <th class="primary--text">Salle</th>
              <th class="primary--text">Adresse</th>
              <th class="primary--text">Description</th>
              <th class="primary--text">Select</th>
            </tr>
          </thead>

          <tbody>
            <tr class="w-100" v-for="(item,index) in arraySalle" :key="index" @dblclick="vue='modifier',assignUpdate(item),itemUpdate=item">
              <td>
                {{index + 1}}
              </td> 
              <td>
                {{item.nom}}
              </td>
              <td>
                {{item.adresse}}
              </td>
              <td>
                {{item.description}}
              </td>
              <td>
                <v-checkbox
                  :key="item.ref+index"
                  v-model="selected"
                  :value="item.ref"
                  @change="controlSelect()"
                ></v-checkbox>
              </td>              
            </tr>
          </tbody>
      </v-simple-table>
     </div>
     <div v-if="vue=='creer'">
      <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Saison</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select @change="controlSave()" v-model="create.saison" :placeholder="'Saison'" :items="saison"></v-select>
                </v-responsive>
              </td>
            </tr>
          </tbody> 
      </v-simple-table>
      <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Salle</th>
              <th class="primary--text">Adresse</th>
              <th class="primary--text">Description</th>
            </tr>
          </thead>

          <tbody>
            <tr class="w-100" v-for="(item,index) in create.salle" :key="index">
              <td style="height:100px;vertical-align: top;">
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.nom" :placeholder="'Nom'"></v-text-field>
                </v-responsive>
              </td>
              <td style="height:100px;vertical-align: top;">
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.adresse" :placeholder="'Adresse'"></v-text-field>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="100%"
                >
                  <v-textarea rows="3" @change="controlSave()" @keyup="controlSave()" v-model="item.description" :placeholder="'Description'"></v-textarea>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table>
     </div>
     <div v-if="vue=='modifier'">
            <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Saison</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select readonly v-model="update.saison" :placeholder="'Saison'" :items="saison"></v-select>
                </v-responsive>
              </td>
            </tr>
          </tbody> 
      </v-simple-table>
      <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Salle</th>
              <th class="primary--text">Adresse</th>
              <th class="primary--text">Description</th>
            </tr>
          </thead>

          <tbody>
            <tr class="w-100" v-for="(item,index) in update.salle" :key="index">
              <td style="height:100px;vertical-align: top;">
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.nom" :placeholder="'Nom'"></v-text-field>
                </v-responsive>
              </td>
              <td style="height:100px;vertical-align: top;">
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.adresse" :placeholder="'Adresse'"></v-text-field>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="100%"
                >
                  <v-textarea rows="3" @change="controlSave()" @keyup="controlSave()" v-model="item.description" :placeholder="'Description'"></v-textarea>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table>
     </div> 
  </div>
</template>
<script>
import Inscriptions from "@/services/Inscription.js"
import Salles from "@/services/Salle.js"
export default {
  name: "Salles",
  data: () => ({
    
    // Salle
    saison:[],
    arraySalle:[],
    arrayType:[{1:"enfant"},{2:"adulte"}],
    inscriptionData:[],
    // Salle
    
    selected:[],
    trash:{
      save:false
    },    
    
    itemUpdate:{},
    update:
    {
    save:false,
    saison:"",
    ref:"",
    salle:[
      {
        nom:"",
        adresse:"",
        description:""
      },
      ]
    },

    create:
    {
    save:false,
    saison:"",
    salle:[
      {
        nom:"",
        adresse:"",
        description:""
      },
      {
        nom:"",
        adresse:"",
        description:""
      },
      {
        nom:"",
        adresse:"",
        description:""
      },
      {
        nom:"",
        adresse:"",
        description:""
      },
      {
        nom:"",
        adresse:"",
        description:""
      },
      {
        nom:"",
        adresse:"",
        description:""
      },
    ]
    },

    voir:{
    saison:"",
    salle:[],
    },

    //a retirer
    arrayInscription:[],

    vue: "voir",
  }),
  beforeMount(){
    this.getSaison()
  },
  mounted() {
    // this.getSalle()
  },
  methods:{
    init(){
      Object.assign(this.$data.create, this.$options.data().create);    
      Object.assign(this.$data.update, this.$options.data().update);    
      Object.assign(this.$data.voir, this.$options.data().voir);
      Object.assign(this.$data.trash, this.$options.data().trash);
      this.itemUpdate = {}    
      this.arraySalle=[]
      this.selected = []
    },

    assignUpdate(item){
      console.log("item",item)
      this.update.saison=this.inscriptionData.find(el=>el.id == item.id_inscription)["ref"]      
      this.update.salle[0].nom=item.nom      
      this.update.salle[0].adresse=item.adresse      
      this.update.salle[0].description=item.description      
      this.update.ref=item.ref      
    },

    controlSelect(){
      if(this.selected.length && this.vue=="voir"){
        this.trash.save = true
      }else{
        this.trash.save = false
      }
    },
    
   async supprimer(){
      console.log("this.selected",this.selected)
      if(this.trash.save){
        let deleteSelected = 
        await Salles.delete(
          {
            array_ref: this.selected,
          }
        )
        console.log("deleteSelected save",deleteSelected)
        this.trash.save = !deleteSelected
        this.selected = []
        let options = {ref_inscription:this.voir.saison}
        this.getSalle(options)
      }
    },    

    capitalization(string){
      return string[0].toUpperCase() + string.slice(1);
    },

    async getSalle(options){
      this.arraySalle = await Salles.getByInscription(options)
    },

    async getSaison(){
      let arrayInscription = await Inscriptions.get()
      console.log("arrayInscription.data",arrayInscription)
      arrayInscription.forEach(element => {
        this.saison.push({text:element.saison,value:element.ref})
      });
      this.inscriptionData = arrayInscription
    },

    async save(){
      console.log("save 1")
      if(this.vue=='modifier' && this.update.save){
        console.log("save 1", this.update)
        let putSalle = 
        await Salles.put(
          {
            ref: this.update.ref,
            nom: this.update.salle[0].nom,
            adresse: this.update.salle[0].adresse,
            description: this.update.salle[0].description,
          }
        )
        console.log("putSalle save",putSalle)
        this.update.save = !putSalle
      }
      
      if(this.vue=='creer' && this.create.save){
        console.log("save 2")
        let postSalle = 
        await Salles.post(
          {
            salle: this.create.salle,
            ref_inscription: this.create.saison,
          }
        )
        // this.create.save = !postSalle
        if(postSalle){
        Object.assign(this.$data.create, this.$options.data().create);
        }
      }
    },
    controlVoir(){
      console.log("test voir")
      if(this.vue=='voir' && this.voir.saison!=""){
        let options = {ref_inscription:this.voir.saison}
        this.getSalle(options)
      }},
    controlSave(){
      console.log("test")

      if(
        this.vue=='creer' &&
        (this.create.saison!="") 
        && this.create.salle[0].nom !=""
        || this.create.salle[1].nom !=""
        || this.create.salle[2].nom !=""
        || this.create.salle[3].nom !=""
        || this.create.salle[4].nom !=""
        || this.create.salle[5].nom !=""
       
      ){
        console.log("this.saisonStart 1",this.create)
        this.create.save=true
      }else{
        console.log("this.saisonStart 2",this.create)
        this.create.save=false
      }
      
      if(
        this.vue=='modifier' &&
        (this.update.saison!=this.inscriptionData.find(el=>el.id == this.itemUpdate.id_inscription)["ref"] && this.itemUpdate.id_inscription!=undefined) 
        || (this.update.salle[0].nom != this.itemUpdate.nom && this.update.salle[0].nom !="" && this.itemUpdate.nom!=undefined)
        || (this.update.salle[0].adresse != this.itemUpdate.adresse && this.itemUpdate.adresse!=undefined)
        || (this.update.salle[0].description != this.itemUpdate.description && this.itemUpdate.description!=undefined)
      ){
        console.log("this.saisonStart 1",this.update)
        console.log("this.saisonStart 1 update",this.itemUpdate)
        this.update.save=true
      }else{
        console.log("this.saisonStart 2",this.update)
        this.update.save=false
      }
    
      
    },
  }
};
</script>
