<template>
  <v-container id="settings" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="tabs"
              background-color="transparent"
              slider-color="white"
            >
              <!-- <span
                class="subheading font-weight-light mx-3"
                style="align-self: center"
                >Tasks:</span
              > -->
              <v-tab class="mr-3">
                <!-- <v-icon class="mr-2"> mdi-bug </v-icon> -->
                Inscriptions
              </v-tab>
              <v-tab class="mr-3">
                <!-- <v-icon class="mr-2"> mdi-cloud </v-icon> -->
                Salles
              </v-tab>
              <v-tab class="mr-3">
                <!-- <v-icon class="mr-2"> mdi-cloud </v-icon> -->
                Categories
              </v-tab>
              <v-tab class="mr-3">
                <!-- <v-icon class="mr-2"> mdi-cloud </v-icon> -->
                Horaires
              </v-tab>
              <v-tab>
                <!-- <v-icon class="mr-2"> mdi-cloud </v-icon> -->
                Tarifs
              </v-tab>
              <v-tab>
                <!-- <v-icon class="mr-2"> mdi-cloud </v-icon> -->
                Paiements
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tabs" class="transparent">
            <v-tab-item v-for="n in 6" :key="n">
              <v-card-text v-if="tabs == 0">
                <Inscriptions></Inscriptions>
              </v-card-text>
              <v-card-text v-if="tabs == 1">
                <Salles></Salles>
              </v-card-text>
              <v-card-text v-if="tabs == 2">
                <Categories></Categories>
              </v-card-text>
              <v-card-text v-if="tabs == 3">
                <Horaires></Horaires>
              </v-card-text>
              <v-card-text v-if="tabs == 4">
                <Tarifs></Tarifs>
              </v-card-text>
              <v-card-text v-if="tabs == 5">
                <Paiements></Paiements>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
          <!-- <v-tabs-items v-model="tabs" class="transparent">
            <v-tab-item v-for="n in 3" :key="n">
              <v-card-text>
                <template v-for="(task, i) in tasks[tabs]">
                  <v-row :key="i" align="center">
                    <v-col cols="1">
                      <v-list-item-action>
                        <v-checkbox v-model="task.value" color="secondary" />
                      </v-list-item-action>
                    </v-col>

                    <v-col cols="9">
                      <div class="font-weight-light" v-text="task.text" />
                    </v-col>

                    <v-col cols="2" class="text-right">
                      <v-icon class="mx-1"> mdi-pencil </v-icon>
                      <v-icon color="error" class="mx-1"> mdi-close </v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items> -->
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Inscriptions from "../component/Inscriptions.vue";
import Salles from "../component/Salles.vue";
import Categories from "../component/Categories.vue";
import Horaires from "../component/Horaires.vue";
import Tarifs from "../component/Tarifs.vue";
import Paiements from "../component/Paiements.vue";
export default {
  name: "Settings",
  components: {
    Inscriptions,
    Salles,
    Horaires,
    Categories,
    Tarifs,
    Paiements,
  },
  data() {
    return {
      tabs: 0,
    };
  },
};
</script>
