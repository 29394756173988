<template>
  <div class="px-5 py-5 elevation-6 rounded">
    <div class="d-flex justify-space-between">
      <div class="d-flex action">
        <v-sheet
        color="primary "
        elevation="6"
        class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
        dark
        :max-width="65"
        @click="vue='voir',init()"
        style="cursor: pointer"
        >
          <v-icon size="32">mdi-clipboard-text</v-icon>
          <span class="text-caption text-uppercase">Voir</span>
        </v-sheet> 
        
        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="vue='creer',getSaison(),init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-pencil</v-icon>
          <span class="text-caption text-uppercase">Créer</span>
        </v-sheet>
      </div>
      <div class="d-flex save">
        <v-sheet
          :color="trash.save?'red':'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="supprimer()"
          style="cursor: pointer"
        >
          <v-icon  size="32">mdi-delete</v-icon>
          <span class="text-caption text-uppercase">Supprimer</span>
        </v-sheet>        

        <v-sheet
          :color="create.save || update.save?'indigo':'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="save()"
          style="cursor: pointer"
        >
          <v-icon  size="32">mdi-content-save</v-icon>
          <span class="text-caption text-uppercase">Garder</span>
        </v-sheet>
      </div>   
    </div>
     <div v-if="vue=='voir'">
        <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Saison</th>
              <th class="primary--text">Type</th>
              <th class="primary--text">Categorie</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select @change="controlVoir()" v-model="voir.saison" :placeholder="'Saison'" :items="saison"></v-select>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select @change="controlVoir()" v-model="voir.type" :placeholder="'Type'" :items="[{text:'Enfant',value:1},{text:'Adulte',value:2}]"></v-select>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="250"
                >
                  <v-select @change="controlVoir()" v-model="voir.categorie" :placeholder="'Catégorie'" :items="categorie"></v-select>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table>
        <v-simple-table v-if="cotisation.length">
          <thead>
            <tr>
              <th class="primary--text">Nombre entrainement / semaine</th>
              <th class="primary--text">Cotisation (€)</th>
              <th class="primary--text">Select</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in cotisation" :key="index" @dblclick="vue='modifier',assignUpdate(item), itemUpdate=item">
              <td>
                {{item.nb_cours}}
              </td>
              <td>{{item.prix}}</td>
              <td>
                <v-checkbox
                  :key="item.ref+index"
                  v-model="selected"
                  :value="item.ref"
                  @change="controlSelect()"
                ></v-checkbox>                
              </td>
            </tr>
          </tbody>
        </v-simple-table>
     </div>
     <div v-if="vue=='creer'"> 
      <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Saison</th>
              <th class="primary--text">Type</th>
              <th class="primary--text">Categorie</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select @change="controlSave()" v-model="create.saison" :placeholder="'Saison'" :items="saison"></v-select>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select @change="controlSave()" v-model="create.type" :placeholder="'Type'" :items="[{text:'Enfant',value:1},{text:'Adulte',value:2}]"></v-select>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="250"
                >
                  <v-select @change="controlSave()" v-model="create.categorie" :placeholder="'Catégorie'" :items="categorie"></v-select>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table>
      <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Nombre entrainement / semaine</th>
              <th class="primary--text">Cotisation (€)</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item,index) in create.cotisation" :key="index">            
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="200"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.nbEntrainement" :placeholder="'Entrainement/semaine'"></v-text-field>                
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.prix" :placeholder="'prix'"></v-text-field>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table>
     </div>
     <div v-if="vue=='modifier'">
      <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Saison</th>
              <th class="primary--text">Type</th>
              <th class="primary--text">Categorie</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select readonly v-model="update.saison" :placeholder="'Saison'" :items="saison"></v-select>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-select readonly v-model="update.type" :placeholder="'Type'" :items="[{text:'Enfant',value:1},{text:'Adulte',value:2}]"></v-select>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="250"
                >
                  <v-select readonly v-model="update.categorie" :placeholder="'Catégorie'" :items="categorie"></v-select>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table>
      <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Nombre entrainement / semaine</th>
              <th class="primary--text">Cotisation (€)</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item,index) in update.cotisation" :key="index">            
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="200"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.nbEntrainement" :placeholder="'Entrainement/semaine'"></v-text-field>                
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="item.prix" :placeholder="'prix'"></v-text-field>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table>
     </div>
  </div>
</template>
<script>
import moment from 'moment'
import Inscriptions from "@/services/Inscription.js"
import Categories from "@/services/Categorie.js"
import Tarifs from "@/services/Tarif.js"

export default {
  name: "Tarifs",
  data: () => ({
    
    // Horaire
    saison:[],
    categorie:[],
    // horaire:[],
    // horaireFilter:[],
    arrayInscription:[],
    // Horaire
    cotisation:[],

    selected:[],
    trash:{
      save:false
    },    
    
    itemUpdate:{},

    update:
    {
    save:false,
    ref:null,
    saison:"",
    type:null,
    categorie:"",
    cotisation:[
      {
        nbEntrainement:"",
        prix:""
      },
    ]
    },

    voir:{
      saison:"",
      type:null,
      categorie:"",
    },

    create:
    {
    save:false,
    ref:null,
    saison:"",
    type:null,
    categorie:"",
    cotisation:[
      {
        nbEntrainement:"",
        prix:""
      },
      {
        nbEntrainement:"",
        prix:""
      },
      {
        nbEntrainement:"",
        prix:""
      },
      {
        nbEntrainement:"",
        prix:""
      },
      {
        nbEntrainement:"",
        prix:""
      },
    ]
    },
    arrayInscription:[],
    vue: "voir",
  }),
  mounted() {
    this.getSaison()
  },
  watch: {
    // 'voir.type': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue type voir",newValue)
    //     if(newValue!="" && this.voir.saison!=""){
    //       // let options = {ref:this.voir.saison}
    //       // this.getHoraire(options)
    //       this.cotisation = this.horaireFilter.filter(element=>element.type == newValue)
    //     }
    //   },
    //   deep: true
    // },
    
    // 'voir.saison': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue saison voir",newValue)
    //     if(newValue!="" && this.voir.type =null){
    //       let options = {ref:newValue}
    //       this.getHoraire(options)
    //     }
    //   },
    //   deep: true
    // },
    
    'update.saison': {
      handler(newValue, oldValue) {
        console.log("newValue saison",newValue)
        if(newValue!="" && this.update.type != null){
          // this.update.categorie = ""
          let options = {type:this.update.type,ref_inscription:newValue}
          this.getCategorieByTypeInscription(options)
        }
      },
      deep: true
    },
    'update.type': {
      handler(newValue, oldValue) {
        console.log("newValue type",newValue)
        if(newValue!="" && this.update.saison != ""){
          // this.update.categorie = ""
          let options = {ref_inscription:this.update.saison,type:newValue}
          this.getCategorieByTypeInscription(options)
        }
      },
      deep: true
    },

    'create.saison': {
      handler(newValue, oldValue) {
        console.log("newValue saison",newValue)
        if(newValue!="" && this.create.type != null){
          this.create.categorie = ""
          let options = {type:this.create.type,ref_inscription:newValue}
          this.getCategorieByTypeInscription(options)
        }
      },
      deep: true
    },
    'create.type': {
      handler(newValue, oldValue) {
        console.log("newValue type",newValue)
        if(newValue!="" && this.create.saison != ""){
          this.create.categorie = ""
          let options = {ref_inscription:this.create.saison,type:newValue}
          this.getCategorieByTypeInscription(options)
        }
      },
      deep: true
    }
  },
  methods:{
    init(){
      // this.cotisation = ""
      // this.categorie = ""
      // this.voir.saison = ""
      // this.voir.type = null
      // this.update.save = false
      // this.create.save = false

      Object.assign(this.$data.create, this.$options.data().create);    
      Object.assign(this.$data.update, this.$options.data().update);    
      Object.assign(this.$data.voir, this.$options.data().voir);
      Object.assign(this.$data.trash, this.$options.data().trash);
      this.itemUpdate = {}
      this.cotisation = []
      this.categorie = []
      this.selected = []
    },
    getInscriptionByID(id){
      let findEl = this.arrayInscription.find(el=>el.id==id)
      console.log("findEl",findEl)
      return findEl.ref
    },

    assignUpdate(item){
      console.log("item",item)
      if(this.vue=="modifier"){
      this.update.saison=this.getInscriptionByID(item.id_inscription)
      this.update.ref=item.ref
      this.update.type=item.type
      this.update.categorie=item.categorie
      this.update.cotisation[0].nbEntrainement=item.nb_cours
      this.update.cotisation[0].prix=item.prix
      }

    },

    controlSelect(){
      if(this.selected.length && this.vue=="voir"){
        this.trash.save = true
      }else{
        this.trash.save = false
      }
    },
    
   async supprimer(){
      console.log("this.selected",this.selected)
      if(this.trash.save){
        let deleteSelected = 
        await Tarifs.delete(
          {
            array_ref: this.selected,
          }
        )
        console.log("deleteSelected save",deleteSelected)
        this.trash.save = !deleteSelected
        this.selected = []
        let options = {
          ref_inscription:this.voir.saison,
          type:this.voir.type,
          categorie:this.voir.categorie
        }
        this.getTarifs(options)
      }
    },
    
    async getTarifs(options){
      let arrayTarifs = await Tarifs.getBySaisonTypeCategorie(options)
      console.log("arrayTarifs.data",arrayTarifs)
      this.cotisation = arrayTarifs
    },

    async getCategorieByTypeInscription(options){
      let arrayCategorie = await Categories.getByTypeAndIscription(options)
      console.log("arrayInscription.data",arrayCategorie)
      this.categorie = []
      arrayCategorie.forEach(element => {
        this.categorie.push({text:`${this.capitalization(element.niveau)} (${element.startAge}${element.endAge?'/'+element.endAge+' ans':''})`,value:element.ref})
      });
    },

    capitalization(string){
      return string[0].toUpperCase() + string.slice(1);
    },
    async getSaison(){
      this.arrayInscription = await Inscriptions.get()
      console.log("arrayInscription.data",this.arrayInscription)
      this.arrayInscription.forEach(element => {
        this.saison.push({text:element.saison,value:element.ref})
      });
    },
    async save(){
      
      if(this.vue=='modifier' && this.update.save){
        console.log("save 1",this.update)
        let putTarifs = 
                await Tarifs.put(
          {
                nb_cours: this.update.cotisation[0].nbEntrainement,
                prix: this.update.cotisation[0].prix,
                ref: this.update.ref,
          }
        )
        console.log("putHoraire save",putTarifs)
        this.update.save = !putTarifs
      }
      
      if(this.vue=='creer' && this.create.save){
        console.log("save 2")
        let postTarifs = 
        await Tarifs.post(
          {
                ref_inscription: this.create.saison,
                type: this.create.type,
                categorie: this.create.categorie,
                cotisation: this.create.cotisation
          }
        )
        // this.create.save = !postTarifs
        if(postTarifs){
          //  this.create.saison=""
          //  this.create.type=null
          //  this.create.categorie=""
            
          //  this.create.cotisation[0].nbEntrainement=""
          //  this.create.cotisation[0].prix=""
        
          //  this.create.cotisation[1].nbEntrainement=""
          //  this.create.cotisation[1].prix=""
        
          //  this.create.cotisation[2].nbEntrainement=""
          //  this.create.cotisation[2].prix=""
        
          //  this.create.cotisation[3].nbEntrainement=""
          //  this.create.cotisation[3].prix=""
        
          //  this.create.cotisation[4].nbEntrainement=""
          //  this.create.cotisation[4].prix=""
          Object.assign(this.$data.create, this.$options.data().create);    
        }
      }

    },
    controlVoir(){
      if(this.vue=="voir"){
        if(this.voir.saison!="" && this.voir.type!=null){
          let options = {type:this.voir.type,ref_inscription:this.voir.saison }
          this.getCategorieByTypeInscription(options)
        }

        if(this.voir.saison!="" && this.voir.type!=null && this.voir.categorie!=""){
          let options = {
            ref_inscription:this.voir.saison,
            type:this.voir.type,
            categorie:this.voir.categorie
          }
          this.getTarifs(options)
        }
      }
    },
    controlSave(){
      console.log("test")
      console.log("$refs",this.$refs)
      
     if(
        this.vue=='creer' &&
            this.create.saison!=""
            && this.create.type!=null
            && this.create.categorie!=""
            
            && ( this.create.cotisation[0].nbEntrainement!=""
            && this.create.cotisation[0].prix!="")
        
            ||( this.create.cotisation[1].nbEntrainement!=""
            && this.create.cotisation[1].prix!="")
        
            || (this.create.cotisation[2].nbEntrainement!=""
            && this.create.cotisation[2].prix!="")
        
            || (this.create.cotisation[3].nbEntrainement!=""
            && this.create.cotisation[3].prix!="")
        
            || (this.create.cotisation[4].nbEntrainement!=""
            && this.create.cotisation[4].prix!="")
        
      ){
        console.log("this.saisonStart 1",this.create)
        this.create.save=true
      }else{
        console.log("this.saisonStart 2",this.create)
        this.create.save=false
      }
      
      if(
        this.vue=='modifier' &&
            (this.update.cotisation[0].nbEntrainement!=this.itemUpdate.nb_cours && this.itemUpdate.nb_cours!=undefined && this.update.cotisation[0].nbEntrainement!="")
            || (this.update.cotisation[0].prix!=this.itemUpdate.prix && this.itemUpdate.prix!=undefined && this.update.cotisation[0].prix!="")
      ){
        console.log("this.saisonStart 1",this.update)
        this.update.save=true
      }else{
        console.log("this.saisonStart 2",this.update)
        this.update.save=false
      }
  
    },
    $moment(date){
      return moment(date).format("DD/MM/YYYY")
    },
    async getInscription(){
      this.arrayInscription = await Inscriptions.get()
    }
  }
};
</script>
