// import axios from "axios"
import Api from '../../config/config.js'

export default {
    async post(options){
        try {
            let postSalle = await Api.instance.post(`/salle/generate`,options)
            console.log("postSalle",postSalle)
            return postSalle.data.create
        } catch (error) {
            console.log(error)
        }
    },

    async put(options){
        try {
            let putSalle = await Api.instance.put(`/salle/update`,options)
            console.log("putSalle",putSalle)
            return putSalle.data.update
        } catch (error) {
            console.log(error)
        }
    },

    async getByInscription(options){
        try {
            let getSalle = await Api.instance.get(`/salle/get-by-saison`,{params:options})
            console.log("getSalleByInscription",getSalle)
            return getSalle.data
        } catch (error) {
            console.log(error)
        }
    },

    async getByArrayInscription(options){
        try {
            let getSalle = await Api.instance.get(`/salle/get-by-array-saison`,{params:options})
            console.log("getSalleByArrayInscription",getSalle)
            return getSalle.data
        } catch (error) {
            console.log(error)
        }
    },

    async delete(options){
        try {
            let deleteSalle = await Api.instance.post(`/salle/delete`,options)
            console.log("deleteSalle",deleteSalle)
            return deleteSalle.data.delete
        } catch (error) {
            console.log(error)
        }
    }
}